import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const AllBasicUtilityServices = lazy(() => import('./BasicUtilityServices/AllBasicUtilityServices'));
const MySubBasicUtilityServices = lazy(() => import('./BasicUtilityServices/MySubBasicUtilityServices'));

const BasicService = lazy(() => import('./BasicUtilityServices/BasicServiceConfiguration'));
const CountryDQG = lazy(() => import('./DQGServices/countryDqg'));

const UserManagement = lazy(()=> import('./usermgmt/userManagement'));
const ValidateUser = lazy(() => import('./usermgmt/validateUser'));
const SetUserPassword = lazy(() => import('./usermgmt/setUserPassword'));
const UserThankYou = lazy(() => import('./usermgmt/userThankYou'));
const userList = lazy(() => import('./usermgmt/userList'));
const declinePage = lazy(() => import('./usermgmt/declinePage'));
const declineUser = lazy(() => import('./usermgmt/declineUser'));



const NewCustomJSService = lazy(() => import('./CustomJSServices/NewCustomJSService'));
const EditCustomJSService = lazy(() => import('./CustomJSServices/NewCustomJSService'));
const EditExistCustomJSService = lazy(() => import('./CustomJSServices/EditExistCustomJSService'));


const AllDQGServices = lazy(() => import('./DQGServices/AllDQGServices'));
const MyDQGServices = lazy(() => import('./DQGServices/MyDQGServices'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/internal_error_after_login'));

const Login = lazy(() => import('./user-pages/Login'));
const Register = lazy(() => import('./user-pages/Register'));
const forgotpassword = lazy(() => import('./user-pages/forgotpassword'));
const changepassword = lazy(() => import('./user-pages/changepassword'));

const Profile = lazy(() => import('./user-books/Profile'));
const ApiDetails = lazy(() => import('./user-books/ApiDetails'));


const CreateCustomLookup = lazy(() => import('./lookup/create_custom_lookup'));
const UpdateCustomLookup = lazy(() => import('./lookup/edit_custom_lookup'));
const AllLookups = lazy(() => import('./lookup/allLookups'));


const PromocodeLookup = lazy(() => import('./advance/promocode_lookup'));
const EditPromocodeLookup = lazy(() => import('./advance/edit_promocode_lookup'));

const PDFServiceUI = lazy(() => import('./advance/pdf_service_ui'));


const allAdvanceServices=lazy(()=> import('./advanceServices/AllAdvanceServices'));
const advanceServiceConfiguration =lazy(()=>import('./advanceServices/AdvanceServiceConfiguration'));
const myAdvanceServices =lazy(()=>import('./advanceServices/MySubscribedAdvanceServices'));



const ManageCounter = lazy(() => import('./advance/manage_counter'));
const EditManageCounter = lazy(() => import('./advance/edit_manage_counter'));
const JunkValueLookup = lazy(() => import('./advance/junk_value_lookup'));
const EditJunkValueLookup = lazy(() => import('./advance/edit_junk_value_lookup'));
const JunkValueSelector = lazy(() => import('./advance/selection_page_for_junkvalue'));
const ManageCounterSelector = lazy(() => import('./advance/selection_page_for_managecounter'));
const PromocodeLookupSelector = lazy(() => import('./advance/selection_page_for_promocode'));



const CreateMultiValCustomLookup = lazy(() => import('./mutli_val_lookup/create_custom_lookup'));
const AllMultiValLookups = lazy(() => import('./mutli_val_lookup/allLookups'));
const EditMultiValCustomLookup = lazy(() => import('./mutli_val_lookup/edit_custom_lookup'));

const AdminAllBasicUtilityServices = lazy(() => import('./AdminBasicUtilityServices/AdminAllBasicUtilityServices'));
const AdminNewBasicUtilityService = lazy(() => import('./AdminBasicUtilityServices/AdminNewBasicService'));
const AdminEditBasicUtilityService = lazy(() => import('./AdminBasicUtilityServices/AdminNewBasicService'));
const AdminBasicService = lazy(() => import('./AdminBasicUtilityServices/AdminBasicServiceConfiguration'));

const PromocodeLookupList = lazy(() => import('./advance/promocode_lookup_list'));
const JunkValueLookupList = lazy(() => import('./advance/junk_value_lookup_list'));

const AdminHelpService = lazy(() => import('./AdminHelpServices/AdminHelpService.js'));


const AccountTagging = lazy(() => import('./account_tagging/account_tagging'));
const AllAccountTagging = lazy(() => import('./account_tagging/all_account_tagging'));
const AccountTaggingConfig = lazy(() => import('./account_tagging/lead_to_account_match_config'));


const MarketoConnections =lazy(() => import('./connections/marketoconnection'));
const SfdcConnections =lazy(() => import('./connections/sfdcconnection'));

const CreateMapping =lazy(()=> import('./sfdc/create_mapping'));
const CreateJob =lazy(()=> import('./sfdc/create_job'));
const ImportJob =lazy(()=> import('./sfdc/import_job'));

const AllCategorizationService =lazy(() => import('./categorization/all_categorization_service'));
const CategorizationManageRule =lazy(() => import('./categorization/categorization_manage_rule'));
const CreateCategorizationService =lazy(() => import('./categorization/create_categorization_service'));

const Success = lazy(() => import('./signup/success'));
const PaymentSuccess = lazy(() => import('./signup/payment_success'));
const ManageBilling = lazy(() => import('./signup/manage_billing'));
const ApiDetail = lazy(() => import('./signup/api_detail'));
const MyPlan = lazy(() => import('./signup/my_plan'));
const Thankyou = lazy(() => import('./signup/thankyou'));
const SetPassword = lazy(() => import('./signup/set_password'));
const ValidateEmail = lazy(() => import('./signup/email_address_validate_lp'));
const CheckoutForm = lazy(() => import('./signup/checkoutform'));
const Pricing = lazy(() => import('./signup/plan_details'));
const SendEmailValidation = lazy(() => import('./signup/trigger_email_address_validate'));

const payasyougo = lazy(()=>import('./signup/payasyougo_plan'))
const payasyougoLevelChangeConfirm = lazy(()=>import('./signup/payasyougo_levelchange_confirm'))
const subscriptionLevelChangeConfirm = lazy(()=>import('./signup/subscription_levelchange_confirm'))
const monthly_sub = lazy(()=>import('./signup/monthly_sub'))
const planChangeConfirmation = lazy(()=>import('./signup/planchange_confirmation'))
const confirm_recharge = lazy(()=>import('./signup/confirmrecharge'))
const genericThankyou = lazy(()=>import('./signup/generic_thankyou'))
const SFDCConnectedApp = lazy(() => import('./sfdc/sfdc_connected_app'));

const SelectPlanType = lazy(() => import('./signup/selectplantype'));
const SelectUpgradePlanType = lazy(() => import('./signup/selectupgradetype'));
const DefaultRechargeAmount = lazy(() => import('./signup/defaultrechargeamount'));

const confirmplan = lazy(() => import('./signup/confirmplan'));
const ActivateUser = lazy(() => import('./signup/activateuser'));
const privacypolicy = lazy(() => import('./signup/privacy_policy'));
const terms_of_use =lazy(() =>import('./signup/terms_of_use'));
const ReSetPassword = lazy(() =>import('./signup/resetpassword'));
const Retainer = lazy(() => import('./signup/retainer'));
const RetainerThankyou = lazy(() => import('./signup/retainer_thankyou'));

const AllBulkTestingJobs = lazy(() =>import('./jobsDeatils/bulkTesting'));
/* Lead Import code block start */
const Templates = lazy(() => import('./LeadImport/templates'))
const TemplateDetail = lazy(() => import('./LeadImport/templateDetail'))
const TemplateDetailProject = lazy(() => import('./LeadImport/templateDetailProject'))
const LeadImport = lazy(() => import('./LeadImport/leadImport'))
const LeadImportProject = lazy(() => import('./LeadImport/leadImportProject'))
const uploadCSV = lazy(() => import('./LeadImport/uploadCSV'));
const leadImportOutputMapping = lazy(() => import('./LeadImport/leadImportOutputMapping'));
const leadImportProjectList = lazy(() => import('./LeadImport/leadImportProjectList'));

class AppRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameType:"unknown"
        }

        //this.getUserType = this.getUserType.bind(this);

    }
    getUserType() {
        const domain = process.env.REACT_APP_domain;
        fetch(domain+'/adminuser/getUsername-type')
            .then((response) =>  {
                if (response.status === 200)
                {
                    response.json().then(res => {
                        this.setState({usernameType:res.usernameType })
                    })
                }
                else
                {
                    this.setState({usernameType:"anonymous" })

                }
            })
            .catch(err => console.error(err));

    }

    render () {
        console.log(this.props.usernameType)

        return (
            <Suspense fallback={<Spinner/>}>

                {

                    this.props.usernameType !== null ?
                        (
                            this.props.usernameType.startsWith('client_') ? (
                                <Switch>


                                    <Route exact path="/dashboard" component={ Dashboard } />
                                    <Route path="/signup/confirmplan" component={confirmplan} />
                                    <Route path="/BasicUtilityServices/AllBasicUtilityServices" component={ AllBasicUtilityServices } />
                                    
                                    <Route path="/BasicUtilityServices/MySubBasicUtilityServices" component={ MySubBasicUtilityServices } />
                                    <Route path="/CustomJSServices/NewCustomJSService" component={ NewCustomJSService } />
                                    <Route path="/CustomJSServices/EditCustomJSService/:srvid" component={ EditCustomJSService } />
                                    <Route path="/CustomJSServices/EditExistCustomJSService" component={ EditExistCustomJSService } />
                                    <Route path="/DQGServices/AllDQGServices" component={ AllDQGServices } />
                                    <Route path="/DQGServices/MyDQGServices" component={ MyDQGServices } />
                                    <Route path="/register" component={ Register} />
                                    <Route path="/fgtpwd" component={ forgotpassword } />
                                    <Route path="/changepassword" component={ changepassword } />
                                    <Route path="/profile" component={ Profile } />
                                    <Route path="/apidetails" component={ ApiDetails } />

                                    <Route path="/404" component={ Error404 } />
                                    <Route path="/500" component={ Error500 } />
                                    <Route path="/BasicUtilityServices/BasicServiceConfiguration/:id" component={ BasicService }   />
                                    <Route path="/DQGServices/CountryDQG/:id" component={CountryDQG} />


                                    <Route path="/advance-service/edit-promocode-lookup" component={EditPromocodeLookup} />
                                    <Route path="/advance-service/manage-counter" component={ManageCounter} />
                                    <Route path="/advance-service/edit-manage-counter" component={EditManageCounter} />
                                    <Route path="/advance-service/junk-value-lookup" component={JunkValueLookup} />
                                    <Route path="/advance-service/edit-junk-value-lookup" component={EditJunkValueLookup} />
                                    <Route path="/advance-service/junk-value-selector" component={JunkValueSelector} />
                                    <Route path="/advance-service/manage-counter-selector" component={ManageCounterSelector} />
                                    <Route path="/advance-service/promocode-lookup-selector" component={PromocodeLookupSelector} />

                                    <Route path="/lookup/create-custom-lookup" component={CreateCustomLookup} />
                                    <Route path="/lookup/update-custom-lookup" component={UpdateCustomLookup} />
                                    <Route path="/lookup/all-lookup" component={AllLookups} />

                                    <Route path="/advance-service/promocode-lookup" component={PromocodeLookup} />
                                    <Route path="/advance-service/html_upload" component={PDFServiceUI} />

                                    <Route path="/AdvanceService/AllAdvanceServices" component={allAdvanceServices}/>
                                    <Route path="/AdvanceServices/AdvanceServiceConfiguration/:id" component={advanceServiceConfiguration}/>
                                    <Route path="/AdvanceServices/MyAdvanceServices" component={myAdvanceServices}/>

                                    <Route path="/connections/marketoConnections" component={MarketoConnections}/>

                                    <Route path="/mcl-lookup/create-lookup" component={CreateMultiValCustomLookup} />
                                    <Route path="/mcl-lookup/bulk-update-lookup/:lkid" component={CreateMultiValCustomLookup} />
                                    <Route path="/mcl-lookup/update-lookup/:lkid" component={EditMultiValCustomLookup} />
                                    <Route path="/mcl-lookup/all-lookup" component={AllMultiValLookups} />

                                    <Route path="/advance-service/promocode-lookup-list" component={PromocodeLookupList} />
                                    <Route path="/advance-service/junk-value-lookup-list" component={JunkValueLookupList} />

                                    <Route path="/account-tagging" component={AccountTagging} />
                                    <Route path="/all-account-tagging" component={AllAccountTagging} />
                                    <Route path="/account-tagging-config" component={AccountTaggingConfig} />

                                    <Route path="/connections/marketoConnections" component={MarketoConnections}/>
                                    <Route path="/connections/sfdcConnections" component={SfdcConnections}/>

                                    <Route path="/advance-service/sfdc/createmapping" component={CreateMapping} />
                                    <Route path="/advance-service/sfdc/createjob/:mappingId/:obj/:type" component={CreateJob} />
                                    <Route path="/advance-service/sfdc/importjob" component={ImportJob} />


                                    <Route path="/signup/success" component={Success} />
                                    <Route path="/signup/paymentsuccess" component={PaymentSuccess} />
                                    <Route path="/signup/genericthankyou" component={genericThankyou}/>
                                    <Route path="/manage-billing" component={ManageBilling} />
                                    <Route path="/api-detail" component={ApiDetail} />
                                    <Route path="/my-plan" component={MyPlan} />
                                    <Route path="/signup/thankyou" component={Thankyou} />
                                    <Route path="/signup/set-password" component={SetPassword} />
                                    <Route path="/signup/validate-email-address" component={ValidateEmail} />
                                    <Route path="/signup/checkout-form" component={CheckoutForm} />
                                    <Route path="/signup/trigger-email-address-email" component={SendEmailValidation} />
                                    <Route path="/pricing" component={Pricing} />

                                    <Route path="/admin/sfdc-connected-app" component={SFDCConnectedApp} />

                                    <Route path="/new-categorization-service" component={CreateCategorizationService} />
                                    <Route path="/manage-categorization-rule" component={CategorizationManageRule} />
                                    <Route path="/all-categorization-service" component={AllCategorizationService} />

                                    <Route path="/signup/selectplantype" component={SelectPlanType} />
                                    <Route path="/signup/selectupgradetype" component={SelectUpgradePlanType} />
                                    <Route path="/signup/defaultrechargeamount" component={DefaultRechargeAmount} />
                                    <Route path="/signup/change-to-payasyougo" component={payasyougo}/>
                                    <Route path="/signup/payasyougo-level-change-confirm" component={payasyougoLevelChangeConfirm}/>
                                    <Route path="/signup/subscription-level-change-confirm" component={subscriptionLevelChangeConfirm}/>

                                    <Route path="/signup/change-to-monthly_sub" component={monthly_sub}/>
                                    <Route path="/signup/confirm-plan-change" component={planChangeConfirmation}/>
                                    <Route path="/signup/privacy_policy" component={privacypolicy}/>
                                    <Route path="/signup/terms_of_use" component={terms_of_use}/>
                                    <Route path="/signup/confirmrecharge" component={confirm_recharge}/>
                                    <Route path="/signup/activateuser" component={ActivateUser}/>



                                    <Route path="/usermgmt/usermgmt" component={UserManagement} />
                                    <Route path="/usermgmt/validate" component={ValidateUser} />
                                    <Route path="/usermgmt/joinrdn" component={SetUserPassword} />
                                    <Route path="/usermgmt/uthk" component={UserThankYou} />
                                    <Route path="/usermgmt/userList" component={userList} />
                                    <Route path="/usermgmt/declinePage" component={declinePage} />
                                    <Route path="/usermgmt/declineUser" component={declineUser} />
                                    <Route path="/jobsDetails/bulktesting" component={AllBulkTestingJobs}/>

                                    {/* Lead Import code block start */}
                                    <Route path='/leadimport/templates' component={Templates} />
                                    <Route path='/leadimport/templateDetail' component={TemplateDetail} />
                                    <Route path='/leadimport/templateDetailProject' component={TemplateDetailProject} />
                                    <Route path="/leadimport/leadimport" component={LeadImport} />
                                    <Route path="/leadimport/leadimportProject" component={LeadImportProject} />
                                    <Route path='/leadimport/uploadCSV' component={uploadCSV} />
                                    <Route path='/leadimport/leadImportOutputMapping' component={leadImportOutputMapping} />
                                    <Route path='/leadimport/leadImportProjectList' component={leadImportProjectList} />

                                    {/* Lead Import code block end */}



                                    <Redirect to="/dashboard" />

                                </Switch>
                            ):(
                                this.props.usernameType === "RWAdmin" ? (
                                    <Switch>
                                        <Route exact path="/dashboard" component={ Dashboard } />
                                        <Route path="/adminBasicUtilityServices/adminAllBasicUtilityServices" component={ AdminAllBasicUtilityServices } />
                                        <Route path="/adminBasicUtilityServices/adminnewbasicservices" component={ AdminNewBasicUtilityService } />
                                        <Route path="/adminBasicUtilityServices/admineditbasicservices/:srvid" component={ AdminEditBasicUtilityService } />
                                        <Route path="/adminBasicUtilityServices/adminBasicServiceConfiguration/:id" component={ AdminBasicService }   />
                                        <Route path="/adminHelps" component={ AdminHelpService } />
                                        <Route path="/retainer" component={Retainer} />
                                        <Route path="/retainer_thankyou" component={RetainerThankyou} />

                                        <Redirect to="/dashboard" />

                                    </Switch>
                                ):(
                                    this.props.usernameType === "anonymous" ? (
                                        <Switch>
                                            <Route path="/signup/confirmplan" component={confirmplan} />
                                            <Route path="/login" component={ Login } />
                                            <Route path="/register" component={ Register} />
                                            <Route path="/fgtpwd" component={ forgotpassword } />
                                            <Route path="/changepassword" component={ changepassword } />
                                            <Route path="/profile" component={ Profile } />
                                            <Route path="/apidetails" component={ ApiDetails } />

                                            <Route path="/signup/success" component={Success} />
                                            <Route path="/signup/paymentsuccess" component={PaymentSuccess} />
                                            <Route path="/signup/genericthankyou" component={genericThankyou}/>
                                            <Route path="/manage-billing" component={ManageBilling} />
                                            <Route path="/my-plan" component={MyPlan} />
                                            <Route path="/signup/thankyou" component={Thankyou} />
                                            <Route path="/signup/set-password" component={SetPassword} />
                                            <Route path="/signup/validate-email-address" component={ValidateEmail} />
                                            <Route path="/signup/checkout-form" component={CheckoutForm} />
                                            <Route path="/signup/trigger-email-address-email" component={SendEmailValidation} />
                                            <Route path="/pricing" component={Pricing} />

                                            <Route path="/signup/selectplantype" component={SelectPlanType} />
                                            <Route path="/signup/selectupgradetype" component={SelectUpgradePlanType} />
                                            <Route path="/signup/defaultrechargeamount" component={DefaultRechargeAmount} />
                                            <Route path="/signup/change-to-payasyougo" component={payasyougo}/>
                                            <Route path="/signup/payasyougo-level-change-confirm" component={payasyougoLevelChangeConfirm}/>
                                            <Route path="/signup/subscription-level-change-confirm" component={subscriptionLevelChangeConfirm}/>

                                            <Route path="/signup/change-to-monthly_sub" component={monthly_sub}/>
                                            <Route path="/signup/confirm-plan-change" component={planChangeConfirmation}/>
                                            <Route path="/signup/privacy_policy" component={privacypolicy}/>
                                            <Route path="/signup/terms_of_use" component={terms_of_use}/>
                                            <Route path="/signup/entpwd" component={ReSetPassword}/>
                                            <Route path="/signup/activateuser" component={ActivateUser}/>


                                            <Route path="/usermgmt/usermgmt" component={UserManagement} />
                                            <Route path="/usermgmt/validate" component={ValidateUser} />
                                            <Route path="/usermgmt/joinrdn" component={SetUserPassword} />
                                            <Route path="/usermgmt/uthk" component={UserThankYou} />
                                            <Route path="/usermgmt/userList" component={userList} />
                                            <Route path="/usermgmt/declinePage" component={declinePage} />
                                            <Route path="/usermgmt/declineUser" component={declineUser} />

                                            <Route path="/404" component={ Error404 } />
                                            <Route path="/500" component={ Error500 } />
                                            <Redirect to="/login" />

                                        </Switch>
                                    ):(
                                        <Spinner/>
                                    )
                                )
                            )
                        ):(
                            <Spinner/>
                        )

                }
            </Suspense>
        );
    }
}

export default AppRoutes;
